import { DigitalLabelDto, DigitalLabelCarrierDto, DigitalLabelItemDto } from 'types/dtos'
import { DigitalLabelModel, DigitalLabelCarrierModel, DigitalLabelItemModel } from 'types/models'

export const transformDigitalLabelItem = (
  response: DigitalLabelItemDto,
): DigitalLabelItemModel => ({
  name: response.name,
  imageUrl: response.image_url,
})

export const transformDigitalLabelCarrier = (
  response: DigitalLabelCarrierDto,
): DigitalLabelCarrierModel => ({
  name: response.name,
  iconUrl: response.icon_url,
})

export const transformDigitalLabel = (response: DigitalLabelDto): DigitalLabelModel => ({
  code: response.code,
  imageUrl: response.image_url,
  carrier: transformDigitalLabelCarrier(response.carrier),
  items: response.items.map(transformDigitalLabelItem),
  receiverName: response.receiver_name,
  deliveryEstimate: response.delivery_estimate,
  expirationDate: response.expiration_date,
  downloadUrl: response.download_url,
  digitalLabelTitle: response.digital_label_title,
  labelExpiresTitle: response.label_expires_title,
  downloadLabelTitle: response.download_label_title,
  headerSubtitle: response.header_subtitle,
})
