import { SellerBadgeDto } from 'types/dtos'
import { SellerBadgeModel } from 'types/models'

export const transformSellerBadgeDto = ({
  type,
  progress: { current, goal },
}: SellerBadgeDto): SellerBadgeModel => ({
  type,
  progress: { current, goal },
})
