import { PhotoTipDto } from 'types/dtos'
import { PhotoTipModel } from 'types/models'

export const transformPhotoTipDto = (photoTip: PhotoTipDto): PhotoTipModel => {
  const { id, title, body, entry_type, sorting_order, image_url } = photoTip

  return {
    id,
    title,
    body,
    entryType: entry_type,
    sortingOrder: sorting_order,
    imageUrl: image_url,
  }
}

export const transformPhotoTipDtos = (photoTips: Array<PhotoTipDto>): Array<PhotoTipModel> =>
  photoTips.map(transformPhotoTipDto)
