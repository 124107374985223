import { ClosetPromoPerformanceDto } from 'types/dtos'
import { ClosetPromoPerformanceModel } from 'types/models'

export const transformClosetPromoPerformanceDto = (
  performanceDto: ClosetPromoPerformanceDto,
): ClosetPromoPerformanceModel => ({
  impressionsPerDay: Object.entries(performanceDto.impressions_per_day).map(entry => ({
    date: entry[0],
    organic: 0,
    promoted: entry[1],
  })),
  profileVisitsPerDay: Object.entries(performanceDto.profile_visits_per_day).map(entry => ({
    date: entry[0],
    organic: entry[1].organic,
    promoted: entry[1].through_promotion,
  })),
  itemVisitsPerDay: Object.entries(performanceDto.item_visits_per_day).map(entry => ({
    date: entry[0],
    organic: entry[1].organic,
    promoted: entry[1].through_promotion,
  })),
  favourites: {
    organic: performanceDto.favourites_organic,
    promoted: performanceDto.favourites_through_promotion,
  },
  conversationsStarted: {
    organic: performanceDto.conversations_started_organic,
    promoted: performanceDto.conversations_started_through_promotion,
  },
  followers: {
    organic: performanceDto.followers_organic,
    promoted: performanceDto.followers_through_promotion,
  },
  endDate: {
    original: performanceDto.closet_promotion_end_date,
    humanized: performanceDto.closet_promotion_end_date_humanized,
  },
  startDate: {
    original: performanceDto.closet_promotion_start_date,
    humanized: performanceDto.closet_promotion_start_date_humanized,
  },
  endsInHours: {
    original: performanceDto.closet_promotion_ends_in_hours,
    humanized: performanceDto.closet_promotion_ends_in_hours_humanized,
  },
  updatedAt: performanceDto.updated_at,
})
