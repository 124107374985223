import { CollectionDatesDto, CollectionDateDto } from 'types/dtos'
import { CollectionDatesModel, CollectionDateModel } from 'types/models'

export const transformCollectionDate = (
  collectionDate: CollectionDateDto,
): CollectionDateModel => ({
  collection: collectionDate.collection,
  collectionTo: collectionDate.collection_to,
  dropOffTo: collectionDate.drop_off_to,
})

export const transformCollectionDates = (
  collectionDates: CollectionDatesDto,
): CollectionDatesModel => ({
  dropOffType: collectionDates.drop_off_type,
  dates: collectionDates.dates.map(transformCollectionDate),
  shipmentDeadline: collectionDates.shipment_deadline,
})
