import { UserBundleItemsResp } from 'types/api'
import { UserBundleItemsModel } from 'types/models'

import { transformItemDtosToProductItems } from './product-item'
import { transformPaginationDto } from './pagination'
import { transformCurrencyAmountDto } from './currency-amount'

export const transformUserBundleItems = (response: UserBundleItemsResp): UserBundleItemsModel => {
  return {
    items: transformItemDtosToProductItems(response.items),
    pagination: transformPaginationDto(response.pagination),
    containsOfflineVerificationItem: response.contains_offline_verification_item,
    offlineVerificationFee: response.offline_verification_fee
      ? transformCurrencyAmountDto(response.offline_verification_fee)
      : null,
  }
}
