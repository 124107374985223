import { PaginationDto } from 'types/dtos'
import { PaginationModel } from 'types/models'

export const transformPaginationDto = (dto: PaginationDto): PaginationModel => ({
  currentPage: dto.current_page,
  totalPages: dto.total_pages,
  totalEntries: dto.total_entries,
  perPage: dto.per_page,
  time: dto.time,
})
