import { InfoBoardPostDto } from 'types/dtos'
import { InfoBoardPostModel } from 'types/models/info-board-post'

export const transformInfoBoardPostDto = (dto: InfoBoardPostDto): InfoBoardPostModel => ({
  id: dto.id,
  title: dto.title,
  subtitle: dto.subtitle,
  body: dto.body,
  layoutType: dto.layout_type,
})

export const transformInfoBoardPostDtos = (
  dtos: Array<InfoBoardPostDto>,
): Array<InfoBoardPostModel> => dtos.map(transformInfoBoardPostDto)
