import { omitBy, isUndefined } from 'lodash'

import clientSideMetrics from 'libs/common/client-side-metrics'
import { logError, logMessage } from 'libs/utils/window'
import { MS_PER_SECOND } from 'constants/date'

const HOME_PREFIX = 'Home'

export const logHomeError = (error: Error | null, feature?: string) => {
  if (!(error instanceof Error)) return

  const loggedFeature = feature ? `${HOME_PREFIX}_${feature}` : HOME_PREFIX

  logError(error, { feature: loggedFeature })
}

export const logHomeMessage = (message: string, extra?: string) => {
  logMessage(message, { feature: HOME_PREFIX, extra })
}

export const incrementPageLoadInitiatedCounter = (tab: string) => {
  clientSideMetrics.counter('home_page_load_initiated', { tab }).increment()
}

export const incrementPageLoadFailureCounter = (labels: {
  tab: string
  feed_reason?: string
  homepage_reason: string
}) => {
  clientSideMetrics.counter('home_page_load_failure', omitBy(labels, isUndefined)).increment()
}

const BUCKETS = [0.05, 0.1, 0.15, 0.25, 0.4, 0.6, 0.8, 1, 1.2, 1.6, 2, 3.5, 5, 7, 10]

export const observeFetchDuration = (
  loadTimeMs: number,
  labels: {
    state: 'success' | 'failure'
    api_type: 'old' | 'new'
  },
) => {
  clientSideMetrics
    .histogram('home_page_load_duration', labels, BUCKETS)
    .observe(loadTimeMs / MS_PER_SECOND)
}
