import { CarrierSettingsFeedbackConfigurationDto } from 'types/dtos'
import { CarrierSettingsFeedbackConfigurationModel } from 'types/models'

export const transformCarrierSettingsFeedback = (
  carrierSettingsFeedbackConfiguration: CarrierSettingsFeedbackConfigurationDto,
): CarrierSettingsFeedbackConfigurationModel | undefined => {
  if (!carrierSettingsFeedbackConfiguration.available_statements[0]) return undefined

  return {
    formTitle: carrierSettingsFeedbackConfiguration.form_title,
    feedback: {
      options: carrierSettingsFeedbackConfiguration.available_statements[0].options.map(option => ({
        body: option.body,
        hasMessageInput: option.has_message_input,
        id: option.id,
      })),
      title: carrierSettingsFeedbackConfiguration.available_statements[0].title,
    },
  }
}
