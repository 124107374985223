import { FeedbackRatingFormDto } from 'types/dtos'
import { FeedbackRatingModel } from 'types/models'

export const transformFeedbackFormDto = ({
  available_statements,
  form_subtitle,
  form_title,
}: FeedbackRatingFormDto): FeedbackRatingModel => {
  const ratingIds: Array<number> = []

  const ratingOptions = available_statements.reduce((previousValue, currentValue) => {
    ratingIds.push(currentValue.rating_id)

    const mappedRatingOptions = currentValue.options.map(option => {
      const { id, body, has_message_input } = option

      return {
        id,
        body,
        hasMessageInput: has_message_input,
      }
    })

    return {
      ...previousValue,
      [currentValue.rating_id]: {
        title: currentValue.title,
        options: mappedRatingOptions,
      },
    }
  }, {})

  return {
    titleText: form_title,
    subtitleText: form_subtitle,
    ratingOptions,
    ratingIds,
  }
}
