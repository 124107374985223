import { ShippingInstructionsDto, ShippingInstructionsCarrierDto } from 'types/dtos'
import { ShippingInstructionsModel, ShippingInstructionsCarrierModel } from 'types/models'

export const transformShippingInstructionsCarrierDto = (
  shippingInstructionsCarrier: ShippingInstructionsCarrierDto,
): ShippingInstructionsCarrierModel => ({
  name: shippingInstructionsCarrier.name,
  iconUrl: shippingInstructionsCarrier.icon_url,
})

export const transformShippingInstructionsDto = (
  shippingInstructions: ShippingInstructionsDto,
): ShippingInstructionsModel => ({
  id: shippingInstructions.id,
  carrier: transformShippingInstructionsCarrierDto(shippingInstructions.carrier),
  instructions: shippingInstructions.instructions,
})
