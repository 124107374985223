import { ShippingContactConfigurationDto, ShippingContactValidationDto } from 'types/dtos'
import { ShippingContactConfigurationModel, ShippingContactValidationModel } from 'types/models'

export const transformShippingContactValidationDto = (
  dto: ShippingContactValidationDto,
): ShippingContactValidationModel => ({
  regex: dto.regex,
  message: dto.message,
  trackingTarget: dto.tracking_target,
})

export const transformShippingContactConfigurationDto = (
  dto: ShippingContactConfigurationDto,
): ShippingContactConfigurationModel => ({
  phoneNumber: {
    validations: dto.phone_number?.validations.map(transformShippingContactValidationDto) || [],
  },
})
